let error = false;

document.addEventListener("DOMContentLoaded", function() {
    acordeonClases();
    inputBuscador();
    mostrarGrupos();
    mostrarHorario();
    notificaciones();
    navegacionResponsive();
    formulario();
    validarURL();
    validarHorario();
    validarDias();
    validarNombreUsuario();
    validarPassword();
    validarMateria();
});

function acordeonClases() {
    let acordion = document.querySelectorAll(".boton-dia");
    let content = document.querySelectorAll(".tabla");
    let icono = document.querySelectorAll(".boton-dia i");
    
    for (let i = 0; i < acordion.length; i++) {
        acordion[i].addEventListener("click", () => {
            if (content[i].style.display == "block") {
                content[i].style.display = "none";
                icono[i].classList.remove("fa-minus");
                icono[i].classList.add("fa-plus");
            } else {
                content[i].style.display = "block";
                icono[i].classList.remove("fa-plus");
                icono[i].classList.add("fa-minus");
            }
        });
    }
}

function inputBuscador(){
    const inputBuscadorGrupo = document.querySelector("#buscarGrupo");
    const inputBuscadorProfesor = document.querySelector("#buscarProfesor");
    const inputBuscadorAlumno = document.querySelector("#buscarAlumno");
    const inputBuscadorMateria = document.querySelector("#buscarMateria");

    if (inputBuscadorGrupo) {
        inputBuscadorGrupo.addEventListener("input", buscarGrupo);
    }

    if (inputBuscadorProfesor) {
        inputBuscadorProfesor.addEventListener("input", buscarProfesor);
    }

    if (inputBuscadorAlumno) {
        inputBuscadorAlumno.addEventListener("input", buscarAlumno);
    }

    if (inputBuscadorMateria) {
        inputBuscadorMateria.addEventListener("input", buscarMateria);
    }
}

function buscarGrupo(e) {
    const expresion = new RegExp(e.target.value, "i"),
          registros = document.querySelectorAll("tbody tr");

        registros.forEach (registro => {
            registro.style.display = "none";

            if (registro.childNodes[1].textContent.replace(/\s/g, " ").search(expresion) != -1){
                registro.style.display = "table-row";
            }
        });
}

function buscarProfesor(e) {
    const expresion = new RegExp(e.target.value, "i"),
          registros = document.querySelectorAll(".buscarProfesor");

        registros.forEach (registro => {
            registro.style.display = "none";

            if (registro.childNodes[1].textContent.replace(/\s/g, " ").search(expresion) != -1){
                registro.style.display = "table-row";
            }
        });
}

function buscarAlumno(e) {
    const expresion = new RegExp(e.target.value, "i"),
          registros = document.querySelectorAll(".buscarAlumno");

    registros.forEach (registro => {
        registro.style.display = "none";

        if (registro.childNodes[1].textContent.replace(/\s/g, " ").search(expresion) != -1){
            registro.style.display = "table-row";
        }
    });
}

function buscarMateria(e) {
    const expresion = new RegExp(e.target.value, "i"),
          registros = document.querySelectorAll("tbody tr");

    registros.forEach (registro => {
        registro.style.display = "none";

        if (registro.childNodes[1].textContent.replace(/\s/g, " ").search(expresion) != -1){
            registro.style.display = "table-row";
        }
    });
}

function mostrarGrupos() {
    const grupo = document.querySelector("#usuarioAlumno");
    const radioTipoUsuario = document.querySelectorAll(".turno-clase input[name='tipoUsuario']");

    radioTipoUsuario.forEach( radio => {
        radio.addEventListener("click", e => {
            valor = e.target.value;
            if (valor === "2") {
                if (grupo.classList.contains = "mostrar") {
                    grupo.classList.remove("mostrar");
                }
            } else if(valor === "3"){
                grupo.classList.add("mostrar");
            }
        });
    });
}

function mostrarHorario(){
    const metodoHorario = document.querySelectorAll("input[name='diasSemana']");

    if (metodoHorario) {
        metodoHorario.forEach(input => input.addEventListener("click", mostrarMetodoHorario));
    }
}

function mostrarMetodoHorario(e) {
    let contenedorHorarioDiv = document.querySelector("#horario-clase");
    let valorCiclo = parseInt(e.target.value);
    let contenido = document.querySelectorAll(".contenedor-horario");
    
    if (contenido) {
        for (element of contenido) {
            element.remove();
        }
    }

    if (valorCiclo == 1 || valorCiclo == 2  || valorCiclo == 3) {

        for (let i = 0; i < valorCiclo; i++) {
            contenido = document.createElement("DIV");
            contenido.classList.add("contenedor-horario");
            contenido.innerHTML = `
                    <select name="idDia${i}" id="dia" required>
                        <option value="" selected disabled>Seleccione Día</option>
                        <option value="1">Lunes</option>
                        <option value="2">Martes</option>
                        <option value="3">Miércoles</option>
                        <option value="4">Jueves</option>
                        <option value="5">Viernes</option>
                    </select>
    
                    <div>
                        <label for="horaInicio">Hora Inicio</label>
                        <input type="time" name="horaInicio${i}" id="horaInicio" step="3600" required>
                    </div>
    
                    <div>
                        <label for="horaFin">Hora Termino</label>
                        <input type="time" name="horaFin${i}" id="horaFin" step="3600" required>
                    </div>
            `;
            contenedorHorarioDiv.appendChild(contenido);
        }
    }
    validarDias();
    validarHorario();
}

function validarHorario() {
    let izquierda = 0, derecha = 0, hora = 0, valorHora = 0, valorFin = 0, valorHoraFin = 0, horaInicioRadio = 0, horaFinRadio = 0;
    const horaInicio = document.querySelectorAll("#horaInicio");
    const horaFin = document.querySelectorAll("#horaFin");

    if (horaInicio) {
        horaInicio.forEach(inicio => {
            inicio.addEventListener("input", e => {

                error = false, izquierda = 11, derecha = 13;

                valorHora = e.target.value;
                hora = valorHora.split(":");

                if (hora[0] < izquierda || hora[0] > derecha) {
                    mostrarAlerta("La Hora de Inicio para el Turno Matutino debe ser entre " + izquierda + ":00 - " + derecha + ":00", "error");
                    setTimeout(() => {
                        inicio.value = "";
                    }, 1500);
                }

                if (hora[1] != 00) {
                    mostrarAlerta("Los Minutos de las Clases deben ser iguales a 00", "error");
                    setTimeout(() => {
                        inicio.value = "";
                    }, 1500);
                }

                horaInicioRadio = hora;

                if (horaInicioRadio && horaFinRadio) {
                    if (horaInicioRadio[0] >= horaFinRadio[0]) {
                        mostrarAlerta("La hora de inicio debe ser menor que la de termino", "error");
                        error = true;
                    }

                    if(horaFinRadio[0] - horaInicioRadio[0] > 1){
                        mostrarAlerta("Las Clases no deben durar más de una hora", "error");
                        error = true;
                        setTimeout(() => {
                            inicio.value = "";
                        }, 1500);
                    }
                }
            });
        });
    }

    if (horaFin) {
        horaFin.forEach(fin => {
            fin.addEventListener("input", e => {

                error = false, izquierda = 12, derecha = 14

                valorHoraFin = e.target.value;
                valorFin = valorHoraFin.split(":");

                if (valorFin[0] < izquierda || valorFin[0] > derecha) {
                    mostrarAlerta("La Hora de Termino para el Turno Matutino debe ser entre " + izquierda + ":00 - " + derecha + ":00", "error");
                    setTimeout(() => {
                        fin.value = "";
                    }, 1500);
                }

                if (valorFin[1] != 00) {
                    mostrarAlerta("Los Minutos de las Clases deben ser iguales a 00", "error");
                    setTimeout(() => {
                        fin.value = "";
                    }, 1500);
                }

                horaFinRadio = valorFin;

                if (horaInicioRadio && horaFinRadio) {
                    if (horaInicioRadio[0] >= horaFinRadio[0]) {
                        mostrarAlerta("La hora de inicio debe ser menor que la de termino", "error");
                        error = true;
                        
                    }

                    if( horaFinRadio[0] - horaInicioRadio[0] > 1){
                        mostrarAlerta("Las Clases no deben durar más de una hora", "error");
                        error = true;
                        setTimeout(() => {
                            fin.value = "";
                        }, 1500);
                    }
                }
            });
        });
    }
}

function validarDias() {
    const dias = document.querySelectorAll("#dia");
    const arrayValorDias = [];
    if (dias) {
        for (let i = 0; i < dias.length; i++) {
            dias[i].addEventListener("input", e => {

                if (dias.length == 1) {
                    error = false;
                }

                if (dias.length == 2) {
                    if (e.target.name == "idDia0") {
                        if (arrayValorDias.length == 2) {
                            arrayValorDias.shift();
                        }

                        arrayValorDias.unshift(e.target.value);
                        console.log(arrayValorDias);
                        
                    } else if (e.target.name == "idDia1") {
                        if (arrayValorDias.length == 2) {
                            arrayValorDias.pop();
                        }
                        
                        arrayValorDias.push(e.target.value);
                        console.log(arrayValorDias);
                        
                    }

                    error = arrayValorDias.some(valoresDiferentes);
                }

                if (dias.length == 3) {
                    if (e.target.name == "idDia0") {
                        if (arrayValorDias.length == 3) {
                            arrayValorDias.shift();
                        }

                        arrayValorDias.unshift(e.target.value);

                    } else if (e.target.name == "idDia1") {
                        if (arrayValorDias.length == 3) {
                            arrayValorDias.splice(1,1);
                        }
                        
                        arrayValorDias.splice(1,0, e.target.value);
                        
                    } else if (e.target.name == "idDia2") {
                        if (arrayValorDias.length == 3) {
                            arrayValorDias.pop();
                        }
                        
                        arrayValorDias.push(e.target.value);
                        
                    }

                    error = arrayValorDias.some(valoresDiferentes);
                }

                if (error) {
                    mostrarAlerta("No se puede repetir el mismo día", "error");
                    e.target.value = "";
                }
            });
        }
    }

}

function valoresDiferentes(valor, indice, lista) {
    return !(lista.indexOf(valor) === indice);
}

function validarURL() {
    expresion = /^(https):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)(:(\d+))?\/?/i;

    inputUrl = document.querySelector("#link");

    if (inputUrl) {
        inputUrl.addEventListener("input", e => {
            url = e.target.value;
    
            if (expresion.test(url)) {
                mostrarAlerta("URL Correcta", "exito");
                error = false
            } else {
                mostrarAlerta("URL errónea", "error");
                error = true;    
            }
        });
    }
}

function validarNombreUsuario() {
    nombreUsuario = document.querySelector("#nombreUsuario");
    apellidoUsuario = document.querySelector("#apellidoUsuario");

    const validador = new RegExp('^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$');

    if (nombreUsuario) {
        nombreUsuario.addEventListener("input", e => {
            nombre = e.target.value;

            if (!nombre) {
                mostrarAlerta("No se admiten espacios en blanco al inicio", "error");
                error = true;
            } else{
                if (nombre.length < 3){
                    mostrarAlerta("El nombre debe tener al menos 3 caracteres", "error");
                    error = true; 
                } else if(validador.test(nombre)) {
                    mostrarAlerta("Nombre Correcto", "exito");
                    error = false
                } else if (nombre.length > 30) {
                    mostrarAlerta("El máximo de caracteres es de 30", "error");
                    error = true;
                } else {
                    mostrarAlerta("Solo se admiten Letras para el Nombre", "error");
                    error = true;
                }
            } 
        });
    }

    if (apellidoUsuario) {
        apellidoUsuario.addEventListener("input", e => {
            apellido = e.target.value;

            if (!apellido) {
                mostrarAlerta("No se admiten espacios en blanco al inicio", "error");
                error = true;
            } else{
                if (apellido.length < 4){
                    mostrarAlerta("El apellido debe tener al menos 4 caracteres", "error");
                    error = true; 
                } else if(validador.test(apellido)) {
                    mostrarAlerta("apellido Correcto", "exito");
                    error = false
                } else if (apellido.length > 40) {
                    mostrarAlerta("El máximo de caracteres es de 40", "error");
                    error = true;
                } else {
                    mostrarAlerta("Solo se admiten Letras para el Apellido", "error");
                    error = true;
                }
            } 
        });
    }
}

function validarPassword(){
    const password = document.querySelector("#password");
    const passwordConfirm = document.querySelector("#passwordConfirm");
    const passwordClase = document.querySelector("#passwordClase");
    const validar = new RegExp('^[A-ZÑ0-9]+$', 'i');

    if (password) {
        password.addEventListener("input", e => {
            valor = e.target.value;

            if (!valor) {
                mostrarAlerta("No se admiten espacios en blanco al inicio", "error");
                error = true;
            } else{
                if (valor.length < 4){
                    mostrarAlerta("La Contraseña debe tener al menos 4 caracteres", "error");
                    error = true; 
                } else if(validar.test(valor)) {
                    mostrarAlerta("Contraseña Correcta", "exito");
                    error = false
                } else if (valor.length > 30) {
                    mostrarAlerta("El máximo de caracteres es de 30", "error");
                    error = true;
                } else {
                    mostrarAlerta("Solo se admiten Letras y Números para la Contraseña", "error");
                    error = true;
                }
            } 
        })
    }

    if (passwordConfirm) {
        passwordConfirm.addEventListener("input", e => {
            valor = e.target.value;

            if (!valor) {
                mostrarAlerta("No se admiten espacios en blanco al inicio", "error");
                error = true;
            } else{
                if (valor.length < 4){
                    mostrarAlerta("La Contraseña debe tener al menos 4 caracteres", "error");
                    error = true; 
                } else if(validar.test(valor)) {
                    mostrarAlerta("Contraseña Correcta", "exito");
                    error = false
                } else if (valor.length > 30) {
                    mostrarAlerta("El máximo de caracteres es de 30", "error");
                    error = true;
                } else {
                    mostrarAlerta("Solo se admiten Letras y Números para la Contraseña", "error");
                    error = true;
                }
            } 
        })
    }

    if (passwordClase) {
        passwordClase.addEventListener("input", e => {
            valor = e.target.value;

            if (!valor) {
                mostrarAlerta("No se admiten espacios en blanco al inicio", "error");
                error = true;
            } else{
                if (valor.length < 4){
                    mostrarAlerta("La Contraseña debe tener al menos 4 caracteres", "error");
                    error = true; 
                } else if(validar.test(valor)) {
                    mostrarAlerta("Contraseña Correcta", "exito");
                    error = false
                } else if (valor.length > 30) {
                    mostrarAlerta("El máximo de caracteres es de 30", "error");
                    error = true;
                } else {
                    mostrarAlerta("Solo se admiten Letras y Números para la Contraseña", "error");
                    error = true;
                }
            } 
        })
    }
}

function validarMateria() {
    nombreMateria = document.querySelector("#nombreMateria");
    const validador = new RegExp('^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$');

    if (nombreMateria) {
        nombreMateria.addEventListener("input", e => {
            nombre = e.target.value;

            if (!nombre) {
                mostrarAlerta("No se admiten espacios en blanco al inicio", "error");
                error = true;
            } else{
                if (nombre.length < 6){
                    mostrarAlerta("El nombre debe tener al menos 6 caracteres", "error");
                    error = true; 
                } else if(validador.test(nombre)) {
                    mostrarAlerta("Nombre Correcto", "exito");
                    error = false
                } else if (nombre.length > 30) {
                    mostrarAlerta("El máximo de caracteres es de 30", "error");
                    error = true;
                } else {
                    mostrarAlerta("Solo se admiten Letras para el Nombre", "error");
                    error = true;
                }
            } 
        });
    }
}

function formulario() {
    const submit = document.querySelector("#submit");
    if (submit) {
        submit.addEventListener("click", e => {
            if (error) {
                e.preventDefault();
                alert("¡Llena correctamente todos los campos pues!");
            }
        });
    }
}

function notificaciones(){
    if (document.querySelectorAll( "main .alerta")) {
        const notificacionError = document.querySelectorAll( "main .alerta");

        notificacionError.forEach( noti => {
            if (noti.classList.contains("alerta")) {
                setTimeout(() => {
                    noti.classList.add("visible");
            
                    setTimeout(() => {
                        noti.classList.remove("visible");
            
                        setTimeout(() => {
                            noti.remove();
                        }, 500);
                    }, 7500);
                }, 100);
            }
        });        
    }

    if (document.querySelector("main p")) {
        const notificacionBuena = document.querySelector("main p");

        if (notificacionBuena.classList.contains("alerta")) {
            setTimeout(() => {
                notificacionBuena.classList.add("visible");
        
                setTimeout(() => {
                    notificacionBuena.classList.remove("visible");
        
                    setTimeout(() => {
                        notificacionBuena.remove();
                    }, 500);
                }, 3500);
            }, 100);
        } 
    }
}

function navegacionResponsive() {
    const mobileMenu = document.querySelector(".mobile-menu");
    const navegacion = document.querySelector(".cerrar-sesion");

    if (mobileMenu) {
        mobileMenu.addEventListener("click", () => {
            navegacion.classList.toggle("mostrar");
        });
    }
}

function mostrarAlerta(mensaje, tipo) {
    const alertaPrevia = document.querySelector(".alerta");
    if (alertaPrevia) {
        alertaPrevia.remove();
    }

    const alerta = document.createElement("P");
    alerta.textContent = mensaje
    alerta.classList.add("alerta");

    if (tipo == "error") {
        alerta.classList.add("error");
    } else if (tipo == "exito") {
        alerta.classList.add("exito");
    }

    const administrador = document.querySelector(".formulario");
    administrador.appendChild(alerta);

    setTimeout(() => {
        alerta.classList.add("visible");

        setTimeout(() => {
            alerta.classList.remove("visible");

            setTimeout(() => {
                alerta.remove();
            }, 500);
        }, 3500);
    }, 100);
}